import * as Yup from 'yup';
import moment from 'moment';
import { currency2number } from './utils/FormatUtils';

export const requiredStr = Yup.string()
	.trim()
	.required('Please fill in.');
export const optionalStr = Yup.string();

export const requiredSelect = Yup.string()
	.trim()
	.required('Please select.');

export const requiredPhoneNum = requiredStr.matches(
	/^(?:8[1-9]|9[0-8])[0-9]{6}$/,
	'Please enter a valid phone number.'
);

export const optionalPhoneNum = optionalStr.matches(
	/^(?:|(8[1-9]|9[0-8])[0-9]{6})$/,
	'Please enter a valid phone number.',
);

export const requiredTodayOrLater = requiredStr.test('Today or later', 'Please enter a valid date from today or after.', v => {
	const enteredDate = moment(v, moment.HTML5_FMT.DATE);
	return enteredDate.isValid() && enteredDate.isSameOrAfter(moment(), 'day');
});

export const requiredTodayOrBefore = requiredStr.test('Today or before', 'Please enter a valid date from today or before.', v => {
	const enteredDate = moment(v, moment.HTML5_FMT.DATE);
	return enteredDate.isValid() && enteredDate.isSameOrBefore(moment(), 'day');
});

export const requiredDate = requiredStr.test('Valid date', 'Please enter a valid date.', v => moment(v,moment.HTML5_FMT.DATE).isValid())

export const optionalTime = optionalStr.test('Time format', 'Please enter a valid time.', v => {
  const enteredTime = moment(v, moment.HTML5_FMT.TIME);
  return enteredTime.isValid();
})

export const requiredEmail = requiredStr.email('Please enter a valid email.');
export const optionalEmail = optionalStr.email('Please enter a valid email.');

export const requiredCurrency = requiredStr.test('Is a number', 'Please enter a valid amount.', v => {
  return !isNaN(Number(currency2number(v)));
})