import 'core-js/stable';
import React, { lazy, Suspense } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { render } from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import 'whatwg-fetch';
import './index.scss';

const ReservationForm = lazy(() => import('./core/reserveForm/ReservationForm').then(m => ({ default: m.ReservationForm })));
const MerchantController = lazy(() => import('./MerchantController').then(m => ({ default: m.MerchantController })));

render(
	<BrowserRouter basename={process.env.BASE_PATH}>
		<Suspense fallback={<div />}>
			<Switch>
				<Route
					exact
					path='/r'
					render={props => (
						<Suspense fallback={<div />}>
							<ReservationForm {...props} />
						</Suspense>
					)}
				></Route>
				<Route>
					<MerchantController />
				</Route>
			</Switch>
		</Suspense>
	</BrowserRouter>,
	document.getElementById('app')
);
