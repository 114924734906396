import { observable } from 'mobx';
import React, { FC, CSSProperties, useState, ChangeEvent, useRef } from 'react';
import { FormText, Input, Label, InputProps } from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { API_DATE_FORMAT } from './Constants';
// @ts-ignore
import TimeInput from 'react-input-time';
import { formatCurrency } from './utils/FormatUtils';

import useOutsideClick from "./useOutsideClick";

export class LabeledInputState {
	@observable value = '';
	@observable errorMessage = '';
	@observable invalid = false;
	@observable touched = false;

	schema: Yup.StringSchema<string>;

	constructor(schema: Yup.StringSchema<string>, initialValue = '') {
		this.schema = schema;
		this.value = initialValue;
	}

	get handler(): Pick<LabeledInputProps, 'value' | 'onChange' | 'onBlur' | 'errorMessage' | 'invalid'> {
		return {
			value: this.value,
			onChange: value => {
        this.value = value;
				this.touched = true;
        this.validate();
			},
			onBlur: () => {
				this.touched = true;
				this.validate();
			},
			errorMessage: this.errorMessage,
			invalid: this.touched && this.invalid,
		};
	}

	validate() {
		try {
			this.schema.validateSync(this.value);
			this.invalid = false;
		} catch (e) {
			/** @type {Yup.ValidationError} */
			const error = e;
			this.invalid = true;
			this.errorMessage = error.errors[0];
		}
	}
}

type LabeledInputProps = {
	label?: string;
	asterisk?: boolean;
	errorMessageAbsolute?: boolean;
	type?: InputProps['type'];
	value: string;
	onChange: (value: string) => void;
	onBlur: () => void;
	invalid: boolean;
	errorMessage: string;
	disabled?: boolean;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  isCurrency?: boolean;
};

export const LabeledInput: FC<LabeledInputProps> = props => {
	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -25, left: 0, right: 0, width: 240 }
		: void 7;

	return (
		<>
			<div style={{ position: 'relative', width: '100%' }}>
				<Label className={`label-box w-100 px-2 mb-0${props.disabled ? ' disabled':''}`}>
					{props.label}
					{props.asterisk && <span className='text-danger'>*</span>}
          <Input
            className={`pl-0 ${props.type === 'select' ? 'custom-select blend-in' : ''}${props.isCurrency ? 'text-right':''}`}
            type={props.type}
            value={props.value}
            onChange={e => props.onChange(props.isCurrency ? formatCurrency(e.currentTarget.value) : e.currentTarget.value)}
            onBlur={props.onBlur}
            invalid={props.invalid}
            children={props.children}
            maxLength={props.maxLength}
            disabled={props.disabled}
          />
				</Label>
				<FormText color='danger' style={errorMessageAbsoluteStyle}>
					{props.invalid ? props.errorMessage : <>&nbsp;</>}{' '}
				</FormText>
			</div>
		</>
	);
};

export const LabeledDateInput: FC<LabeledInputProps> = props => {
	const [focused, setFocused] = useState<boolean | null>(false);

	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -20, left: 0, right: 0 }
		: void 7;

	return (
		<>
			<div style={{ position: 'relative', width: '100%' }}>
				<Label className={`label-box w-100 px-2 mb-0${props.disabled ? ' disabled':''}`}>
					{props.label}
					{props.asterisk && <span className='text-danger'>*</span>}
          <SingleDatePicker
            id='datePicker'
            date={(props.value && moment(props.value)) || null}
            onDateChange={(date: moment.Moment | null) => {
              props.onChange(date ? date.format(API_DATE_FORMAT) : '');
            }}
            focused={!!focused}
            onFocusChange={({ focused }) => setFocused(focused)}
            numberOfMonths={1}
            placeholder='DD/MM/YYYY'
            displayFormat='DD/MM/YYYY'
            block
            noBorder
            isOutsideRange={()=>false}
            disabled={props.disabled}
          />
				</Label>
				<FormText color='danger' style={errorMessageAbsoluteStyle}>
					{props.invalid ? props.errorMessage : <>&nbsp;</>}{' '}
				</FormText>
			</div>
		</>
	);
};

export const LabeledTimeInput: FC<LabeledInputProps> = props => {
	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -20, left: 0, right: 0 }
		: void 7;

	return (
		<>
			<div style={{ position: 'relative', width: '100%' }}>
				<Label className={`label-box w-100 px-2 mb-0${props.disabled ? ' disabled':''}`}>
					{props.label}
					{props.asterisk && <span className='text-danger'>*</span>}
          <TimeInput
            className="input-time"
            initialTime={props.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
          />
				</Label>
				<FormText color='danger' style={errorMessageAbsoluteStyle}>
					{props.invalid ? props.errorMessage : <>&nbsp;</>}{' '}
				</FormText>
			</div>
		</>
	);
};

export const UnlabeledInput: FC<LabeledInputProps> = props => {
	const [focused, setFocused] = useState<boolean | null>(false);

	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -20, left: 0, right: 0 }
		: void 7;

	return (
		<>
			<div style={{ position: 'relative'}} className="w-100 mr-3">
        {props.asterisk && <span className='text-danger'>*</span>}
        {props.type === 'date' ? (
          <SingleDatePicker
            id='datePicker'
            date={(props.value && moment(props.value)) || null}
            onDateChange={(date: moment.Moment | null) => {
              props.onChange(date ? date.format(API_DATE_FORMAT) : '');
            }}
            focused={!!focused}
            onFocusChange={({ focused }) => setFocused(focused)}
            numberOfMonths={1}
            placeholder='DD/MM/YYYY'
            displayFormat='DD/MM/YYYY'
            block
            noBorder
          />
        ) : (
          <Input
            className={`bordered ${props.type === 'select' ? 'custom-select blend-in' : ''}`}
            type={props.type}
            value={props.value}
            onChange={e => props.onChange(e.currentTarget.value)}
            onBlur={props.onBlur}
            invalid={props.invalid}
            children={props.children}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
          />
        )}
				<FormText color='danger' style={errorMessageAbsoluteStyle}>
					{props.invalid ? props.errorMessage : <>&nbsp;</>}{' '}
				</FormText>
			</div>
		</>
	);
};

export const LineInput: FC<LabeledInputProps> = props => {
	const [focused, setFocused] = useState<boolean | null>(false);

	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -20, left: 0, right: 0}
		: void 7;

	return (
		<div style={{ position: 'relative' }}>
			{props.type === 'date' ? (
				<SingleDatePicker
					id='datePicker'
					date={(props.value && moment(props.value)) || null}
					onDateChange={(date: moment.Moment | null) => {
						props.onChange(date ? date.format(API_DATE_FORMAT) : '');
					}}
					focused={!!focused}
					onFocusChange={({ focused }) => setFocused(focused)}
					numberOfMonths={1}
					placeholder='DD/MM/YYYY'
					displayFormat='DD/MM/YYYY'
					block
					noBorder
				/>
			) : (
				<Input
					type={props.type}
					value={props.value}
					onBlur={props.onBlur}
					invalid={props.invalid}
					onChange={e => props.onChange(e.currentTarget.value)}
					disabled={props.disabled}
					children={props.children}
					className={props.className}
					placeholder={props.label}
				/>
			)}
			{props.invalid && <FormText color='danger' >
				{props.errorMessage}
			</FormText>}
		</div>
	);
};

export const LineInput2: FC<LabeledInputProps> = props => {
	const [focused, setFocused] = useState<boolean | null>(false);

	const errorMessageAbsoluteStyle: CSSProperties | undefined = props.errorMessageAbsolute
		? { position: 'absolute', bottom: -20, left: 0, right: 0}
		: void 7;
	
	const [showTooltip, setShowTooltip] = useState(false);

	const ref:any = useRef();

	useOutsideClick(ref, () => {
		if (showTooltip) setShowTooltip(false);
	});

	return (
		<div style={{ position: 'relative' }}>
			{props.type === 'date' ? (
				<SingleDatePicker
					id='datePicker'
					date={(props.value && moment(props.value)) || null}
					onDateChange={(date: moment.Moment | null) => {
						props.onChange(date ? date.format(API_DATE_FORMAT) : '');
					}}
					focused={!!focused}
					onFocusChange={({ focused }) => setFocused(focused)}
					numberOfMonths={1}
					placeholder='DD/MM/YYYY'
					displayFormat='DD/MM/YYYY'
					block
					noBorder
				/>
			) : (
				<div style={{position:'relative'}}>
					<Input
						type={props.type}
						value={props.value}
						onBlur={props.onBlur}
						invalid={props.invalid}
						onChange={e => props.onChange(e.currentTarget.value)}
						disabled={props.disabled}
						children={props.children}
						className={props.className}
						placeholder={props.label}
					/>
					<div className="infoquest">
						<img src={require('./../images/question.png')} alt="question" className='question'  onClick={() => setShowTooltip(!showTooltip)}/>
						{showTooltip && 
						<span ref={ref} className="tooltiptext">Promo code is reserved for members only. Sign up for our free membership now to enjoy exclusive perks & privileges (T&C apply) - <a href="https://imkim.jarcrm.io/signup" target="_blank">https://imkim.jarcrm.io/signup</a></span>}
						<span className="tooltiptext2">Promo code is reserved for members only. Sign up for our free membership now to enjoy exclusive perks & privileges (T&C apply) - <a href="https://imkim.jarcrm.io/signup" target="_blank">https://imkim.jarcrm.io/signup</a></span>
					</div>
				</div>
			)}
			{props.invalid && <FormText color='danger' >
				{props.errorMessage}
			</FormText>}
		</div>
	);
};
